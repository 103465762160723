/**
 * Created by osirvent on 07/03/2017.
 */
angular.module('annexaApp')
    .directive('annexaSignApplet',['$compile', '$location', '$rootScope', function ($compile, $location, $rootScope) {
        var getTemplate = function() {
            var appletProtocol = $rootScope.app.configuration.sign_applet_protocol.value;
            var baseUrlForJars = appletProtocol + '://' + $location.$$host + ($location.$$port != 80 && $location.$$port != 443 ? ':' + $location.$$port : '');

            var template = '';

            template += '<object classid = "clsid:8AD9C840-044E-11D1-B3E9-00805F499D93" width="0" height="0" id="appletcatcert">';
            template += '   <param name = "code" value = "org.catcert.AppletSignatura">';
            template += '   <param name = "archive" value = "' + baseUrlForJars + '/libs/appletCATCert/appletCATCert2.6.7.jar, ' + baseUrlForJars + '/libs/appletCATCert/CATCertXMLlib1.2.4.jar, ' + baseUrlForJars + '/libs/appletCATCert/CATCertCMSlib1.3.3.jar, ' + baseUrlForJars + '/libs/appletCATCert/CATCertPDFlib1.3.2.jar">';
            template += '   <param name = "mayscript" value = "true">';
            template += '   <param name = "scriptable" value = "true">';
            template += '   <param name = "type" value = "application/x-java-applet">';
            template += '   <param name = "keystore_type" value = "0">';
            template += '   <param name = "signature_mode" value = "24">';
            template += '   <param name = "embedded" value = "true">';
            template += '   <param name = "doc_type" value = "6">';
            template += '   <param name = "js_event" value = "true">';
            template += '   <param name = "TimeStamp_CMS_signature" value = "true">';
            template += '   <param name = "pdf_reserved_space" value = "25">';
            template += '   <comment>';
            template += '       <embed ';
            template += '           type = "application/x-java-applet" ';
            template += '           code = "org.catcert.AppletSignatura" ';
            template += '           archive = "' + baseUrlForJars + '/libs/appletCATCert/appletCATCert2.6.7.jar, ' + baseUrlForJars + '/libs/appletCATCert/CATCertXMLlib1.2.4.jar, ' + baseUrlForJars + '/libs/appletCATCert/CATCertCMSlib1.3.3.jar, ' + baseUrlForJars + '/libs/appletCATCert/CATCertPDFlib1.3.2.jar" ';
            template += '           width = "0" height = "0" ';
            template += '           name = "appletcatcert" ';
            template += '           mayscript = "true" ';
            template += '           scriptable = "true" ';
            template += '           keystore_type = "0" ';
            template += '           signature_mode = "24" ';
            template += '           embedded = "true" ';
            template += '           doc_type = "6" ';
            template += '           js_event = "true" ';
            template += '           TimeStamp_CMS_signature = "true" ';
            template += '           pdf_reserved_space = "25" ';
            template += '       >';
            template += '           <noembed>';
            template += '               No embed';
            template += '           </noembed>';
            template += '       </embed>';
            template += '   </comment>';
            template += '</object>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: false,
            controller: ['$scope', function ($scope) {
            }],
            link: function (scope, element, attr) {
                element.html(getTemplate()).show();
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('annexaSignResult',['$compile', '$timeout',  function($compile, $timeout) {
        var getTemplate = function(scope) {
            var template = '';
			if(scope && scope.autofirma){
	            template += '   <div> '; 
			}else{
	            template += '   <div class="col-sm-12"> '; 
			}
            template += '       <h5 class="m-t ">';
            template += '           <span>{{globalSignState | translate}}</span>';
            template += '       </h5>';
            template += '       <ul class="list inset m-a-0 row">';
            template += '           <li class="list-item" ng-repeat="signature in documentsToSign">';
            template += '               <div class="col-sm-12"  ng-if="(signature.type == \'server\' || signature.type == \'cloudSignAndorra\') && $index == 0">';
            template += '                   <p class="small m-b-0">{{\'global.literals.sendToSignAllDocuments\' | translate}}</p>';
            template += '               </div>';
            template += '               <div class="col-sm-10"  ng-if="signature.type != \'server\' && signature.type != \'cloudSignAndorra\'">';
            template += '                   {{signature.docCode}}';
            template += '                   <p class="small m-b-0">{{signature.document.name}}</p>';
            template += '               </div>';
            template += '               <div class="col-sml-2" ng-if="signature.type != \'server\' && signature.type != \'cloudSignAndorra\'">';
            template += '                   <span class="pull-right" title="{{cancelLiteral}}" ng-if="signature.idDocGui"><a href class="btn-sm b-a-0 grey-pencil text-danger" ng-click="cancelSign(signature.idDocGui)" title="{{cancelLiteral}}"> <i class="fa fa-close"></i> <span class="sr-only">{{cancelLiteral}}</span></a></span>';
            template += '                   <span class="pull-right" ng-if="signature.signState == \'pending\'"><circleicon size="24" class="white"><i name="spinner" class="fa-spin fa fa-spinner"></i></circleicon></span>';
            template += '                   <span class="pull-right" ng-if="signature.signState == \'complete\'"><circleicon size="24" class="success"><i class="fa fa-check"></i></circleicon></span>';
            template += '                   <span class="pull-right" ng-if="signature.signState == \'error\'" title="{{signature.error}}"><circleicon size="24" class="danger"><i class="fa fa-exclamation"></i></circleicon></span>';
            template += '               </div>';
            template += '               <div class="row" ng-if="signature.type != \'server\' && signature.signState == \'error\'">';
            template += '                   <p class="col-sm-12 p-t-sm p-x-md">';
            template += '                       <span class="text-danger">{{signature.error}}</span>';
            template += '                   </p>';
            template += '               </div>';
            template += '           </li>';
            template += '       </ul>';
            template += '   </div>';
            template += '</div>';

            return template;
        }

        return {
            restrict: 'E',
            replace: true,
            scope: {
                documentsToSign: '=',
				autofirma: '='
            },
            controller: ['$scope', '$rootScope', '$http', '$filter', '$window', '$document', '$location', '$q', 'SignAppletFactory', 'SignService', 'Language', function($scope, $rootScope, $http, $filter, $window, $document, $location, $q, SignAppletFactory, SignService, Language) {
                $scope.globalSignState = 'global.sign.configModal.enProces';
                $scope.cancelLiteral = $filter('translate')('global.literals.CANCEL');
                $scope.completed = 0;
				var signConfig = $rootScope.app.configuration.sign_util_configuration;
                SignAppletFactory.getApplet();

                var checkCompleted = function () {
                    $scope.completed++;

                    if($scope.completed == $scope.documentsToSign.length) {
                        $scope.globalSignState = 'global.sign.configModal.titleFinalitzat';
                        angular.element('#signWizardModal .modal-footer button').removeClass('disabled');
                    }
                }

                var promisesLocalSignatures = [];
				var promisesAutofirmaSignatures = [];
                var docsToSignrootScope = [];
               _.forEach($scope.documentsToSign, function(signature, key) {
            	   $scope.globalSignState = 'global.sign.configModal.titleFinalitzat';
                   angular.element('#signWizardModal .modal-footer button').removeClass('disabled');
                    if(signature.type == 'server') {
                    	docsToSignrootScope.push({id:signature.id,docName:signature.document.name});
                        $http({
                            method: 'POST',
                            url: './api/sign/do',
                            params: {
                                signProcessActionId: signature.id,
                                documentId: signature.document.id,
                                certId: signature.certificate.id,
                                pin: signature.pin,
                                position: signature.position,
                                firstpage: signature.firstPage,
                                order: signature.actionOrder,
                                addComments:signature.addComments
                            }
                        });
                    } else if(signature.type == 'local') {
                        promisesLocalSignatures.push(
                            {
                                promise: $http({ method: 'GET', url: './api/sign/signature', params: { id: signature.document.id } }),
                                key: key,
                                signature: signature
                            });
                    } else if (signature.type == 'cloudSignAndorra') {
                    	docsToSignrootScope.push({id:signature.id,docName:signature.document.name});
                    	$http({
                            method: 'POST',
                            url: './api/sign/doSignCloudSignAndorra',
                            data: JSON.stringify({ documentId: $scope.documentsToSign[key].document.id, tempPathfileName: $scope.documentsToSign[key].tempPathfileName, signProcessActionId: $scope.documentsToSign[key].id, urlCloudSignAndorra: $scope.documentsToSign[key].urlCloudSignAndorra })
                        });
                    }else if (signature.type == 'bioSign') {
                    	$http({
                            method: 'POST',
                            url: './api/sign/createDocumentSignBiometric',
                            params: {
	                            signProcessActionId: signature.id,
	                            documentId: signature.document.id,
	                            diviceSign: signature.diviceSign,
	                            nameSignedThird: signature.nameSignedThird,
	                            docSignedThird:signature.docSignedThird,
	                            position: signature.position,
	                            firstpage: signature.firstPage,
	                            order: signature.actionOrder
                            }
                        }).success(function(ret, status) {
                        	if(ret && ret.id){
                        		signature.idDocGui = ret.id;
                        		$http({
                                    method: 'POST',
                                    url: './api/sign/doSignBiometric',
                                    params: {
        	                            signProcessActionId: signature.id,
        	                            documentId: signature.document.id,
        	                            diviceSign: signature.diviceSign,
        	                            nameSignedThird: signature.nameSignedThird,
        	                            docSignedThird:signature.docSignedThird,
        	                            position: signature.position,
        	                            firstpage: signature.firstPage,
        	                            order: signature.actionOrder,
        	                            idDocGui: ret.id
                                    } 
                                });
                        		var getStatusDocument = function(){
                        			$http({
                                        method: 'GET',
                                        url: './api/sign/statusSign',
                                        params: {
                                        	idDocGui: ret.id,
                                        	signProcessActionId: signature.id
                                        }
                            		}).success(function(retStatus, status) {
                                        if(retStatus && retStatus.result) {
                                        	checkCompleted();
                                        	signature.idDocGui = undefined;
                                            $scope.documentsToSign[key].signState = 'complete';
                                        } else if(retStatus && retStatus.error){
                                        	if(!(signature.signState && signature.signState == 'error')){
                                        		checkCompleted();
	                                        	signature.idDocGui = undefined;
	                                            $scope.documentsToSign[key].signState = 'error';
	                                            if(retStatus && retStatus.error == 'Error rejected'){
	                                            	$scope.documentsToSign[key].error = $filter('translate')('global.signState.REJECTED');
	                                            }else{
	                                            	 $scope.documentsToSign[key].error = $filter('translate')('global.errors.unknownSign')
	                                            }
                                        	}
                                        }else{
                                        	$timeout(getStatusDocument, 5000);
                                        }
                                    }).error(function(msg, code) {
                                    	if(!(signature.signState && signature.signState == 'error')){
	                                        checkCompleted();
	                                        signature.idDocGui = undefined;
	                                        $scope.documentsToSign[key].signState = 'error';
	                                        $scope.documentsToSign[key].error = $filter('translate')('global.errors.unknownSign');
	                                        $scope.documentsToSign[key].error += ': ' + msg;
                                    	}
                                    });
                        		}
                        		getStatusDocument();
                        	}else{
                        		checkCompleted();
                        		signature.idDocGui = undefined;
                                $scope.documentsToSign[key].signState = 'error';
                                $scope.documentsToSign[key].error = $filter('translate')('global.errors.unknownSign');
                        	}
                        }).error(function(msg, code) {
                            checkCompleted();
                            signature.idDocGui = undefined;
                            $scope.documentsToSign[key].signState = 'error';
                            $scope.documentsToSign[key].error = $filter('translate')('global.errors.unknownSign');
                            $scope.documentsToSign[key].error += ': ' + msg;
                        });
                    } else if(signature.type == 'autofirma') {
                        promisesAutofirmaSignatures.push(SignService.getBase64Document(signature.document.id));
                    }
               });
               if(docsToSignrootScope && docsToSignrootScope.length > 0){
            	   $rootScope.$broadcast('sentToSignProcessesAdded',{actionsSigned: docsToSignrootScope});
               }
               $scope.cancelSign = function(id){
            	   if(id){
            		   var document = $linq($scope.documentsToSign).firstOrDefault(undefined, "x =>x.idDocGui && x.idDocGui == '"+id+"'");
            		   if(document){
	            		   $http({
	                           method: 'DELETE',
	                           url: './api/sign/deleteBiometricDocument',
	                           params: {
	                        	   idDocGui: id
	                           }
		               		}).success(function(retStatus) {
	                           if(retStatus) {
	                           		checkCompleted();
	                           		document.signState = 'error';
	                           		document.error = $filter('translate')('global.literals.CANCELLED');
	                           		document.idDocGui = undefined;
	                           }
		               		}).error(function(msg, code) {
	                    	   var a = 0;
	                       });
            		   }
            	   }
               }
               
               if(promisesLocalSignatures.length != 0) {
                   var promises = $linq(promisesLocalSignatures).select("x => x.promise").toArray();
                   var prefix = $location.$$protocol + "://" + $location.$$host + ($location.$$port != 80 ? ':' + $location.$$port : '');

                   $q.all(promises)
                       .then(function(data) {
                           _.forEach(data, function(item, index) {
                               SignAppletFactory.addSignature(promisesLocalSignatures[index].signature, item.data.documentUrl.replace('.', prefix), item.data.sizes);
                           });

                           SignAppletFactory.executeSignatures();
                       }).catch(function(error) {
                           _.forEach(promisesLocalSignatures, function(item) {
                               $scope.documentsToSign[item.key].signState = 'error';
                               $scope.documentsToSign[item.key].error = $filter('translate')('global.errors.unknownSign');
                               $scope.documentsToSign[item.key].error += ': ' + error;
                           })
                   })
               }

				if(promisesAutofirmaSignatures.length != 0) {
                  $q.all(promisesAutofirmaSignatures).then(function(data) {
                       _.forEach(data, function(ret, index) {
							if(ret && ret.documentBase64 && ret.sizes){
			                    try{
									$rootScope.loadingDiv = true;
									var params = 'signReason='+((signConfig && signConfig.signature && signConfig.signature.reason && signConfig.signature.reason[Language.getActiveColumn()])?signConfig.signature.reason[Language.getActiveColumn()]:'Sign');
									params+='\nsignerContact='+ $rootScope.app.entity.name;
									if(signConfig && signConfig.showSignature && signConfig.signature.signatureProperties && signConfig.signature.position && $scope.documentsToSign[index].position > 0){
										var w = parseInt(signConfig.signature.position.width);
							            var h = parseInt(signConfig.signature.position.height);
										var px = signConfig.signature.signatureProperties['positionX'+$scope.documentsToSign[index].position];
										var py = signConfig.signature.signatureProperties['positionY'+$scope.documentsToSign[index].position];
										if(signConfig.signature.position.xInverse) {
											px = parseInt(ret.sizes[1])-px;
										}
										if(signConfig.signature.position.yInverse) {
											py = parseInt(ret.sizes[0])-py;
										}
		
							            params+='\nsignaturePositionOnPageLowerLeftX='+ px;
										params+='\nsignaturePositionOnPageLowerLeftY='+ py;
										if(signConfig.signature.position.vertical) {
								            params+='\nsignaturePositionOnPageUpperRightX='+(px + h);
											params+='\nsignaturePositionOnPageUpperRightY='+(py + w);
										}else{
											params+='\nsignaturePositionOnPageUpperRightX='+(px + w);
											params+='\nsignaturePositionOnPageUpperRightY='+(py + h);
										}
										params+='\nsignaturePage=1';
							            if(signConfig.signature.position.vertical) {
							                params +='\nsignatureRotation=90';
							            }
										var isDelegated = false;
										if($scope.documentsToSign[index].userMustSign && $scope.documentsToSign[index].userMustSign.id != $rootScope.LoggedUser.id){
											isDelegated = true;
										}
										if(isDelegated && signConfig.signature.text && signConfig.signature.text.textDelegated && signConfig.signature.text.textDelegated[Language.getActiveColumn()]){
											var text = signConfig.signature.text.textDelegated[Language.getActiveColumn()];
											text = text.replaceAll('[CN]', '$$$SUBJECTCN$$$');
											text = text.replaceAll('[position]', (($scope.documentsToSign[index].userPosition && $scope.documentsToSign[index].userPosition[Language.getActiveColumn()])?$scope.documentsToSign[index].userPosition[Language.getActiveColumn()]:''));
											text = text.replaceAll('[signDate]', '$$$SIGNDATE=dd/MM/yyyy$$$');
											text = text.replaceAll('[CN1]', (($scope.documentsToSign[index].userMustSign && $scope.documentsToSign[index].userMustSign.completeName)?$scope.documentsToSign[index].userMustSign.completeName :''));
											params +='\nlayer2Text='+text;
										}else if(signConfig.signature.text && signConfig.signature.text.text && signConfig.signature.text.text[Language.getActiveColumn()]){
											var text = signConfig.signature.text.text[Language.getActiveColumn()];
											text = text.replaceAll('[CN]', '$$$SUBJECTCN$$$');
											text = text.replaceAll('[position]', (($scope.documentsToSign[index].userPosition && $scope.documentsToSign[0].userPosition[Language.getActiveColumn()])?$scope.documentsToSign[index].userPosition[Language.getActiveColumn()]:''));
											text = text.replaceAll('[signDate]', '$$$SIGNDATE=dd/MM/yyyy$$$');
											params +='\nlayer2Text='+text;
										}
										if(signConfig.signature.text && signConfig.signature.text.fontSize){
											params +='\nlayer2FontSize='+signConfig.signature.text.fontSize;
										}
									}
									var dataB64 = ret.documentBase64;
									var algorithm = "SHA512withRSA";
					                if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.autofirma_properties && $rootScope.app.configuration.autofirma_properties.algorithm){
										algorithm = $rootScope.app.configuration.autofirma_properties.algorithm;
									}
					                var format = "PAdES";
									if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.autofirma_properties && $rootScope.app.configuration.autofirma_properties.format){
										format = $rootScope.app.configuration.autofirma_properties.format;
									}
									if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.autofirma_properties && $rootScope.app.configuration.autofirma_properties.policy){
										params+='\n'+$rootScope.app.configuration.autofirma_properties.policy;
									}
					                var signaturaAutofirmaRealitzada = function (signaturaB64, certificatB64, dadesAddicionals) {
										$http({
				                           method: 'POST',
				                           url: './api/sign/dolocal',
				                           data: {
				                               signed: signaturaB64,
				                               documentId: $scope.documentsToSign[index].document.id,
				                               order: $scope.documentsToSign[index].actionOrder,
				                               signProcessActionId: $scope.documentsToSign[index].id,
				                               position: $scope.documentsToSign[index].position,
				                               firstpage: true
				                           }
				                       }).then(function(data) {
										   $rootScope.loadingDiv = false;
										   checkCompleted();
				                           if(data) {
				                               $scope.documentsToSign[index].signState = 'complete';
				                           } else {
				                               $scope.documentsToSign[index].signState = 'error';
				                               $scope.documentsToSign[index].error = $filter('translate')(data.error);
				                           }
				                       }).catch(function(error) {
										   $rootScope.loadingDiv = false;
				                           checkCompleted();
				                           $scope.documentsToSign[index].signState = 'error';
				                           $scope.documentsToSign[index].error = $filter('translate')('global.errors.unknownSign');
				                           $scope.documentsToSign[index].error += ': ' + error;
				                       })
						            }                    
						            var signaturaAutofirmaError = function(tipusError, missatgeError){
										$rootScope.loadingDiv = false;
										SignService.updateScreen().then(function(data) {
											$scope.documentsToSign[index].signState = 'error';
		                        			$scope.documentsToSign[index].error = $filter('translate')('global.errors.unknownSign')+": "+missatgeError;
										}).catch(function(error) {
											$scope.documentsToSign[index].signState = 'error';
		                        			$scope.documentsToSign[index].error = $filter('translate')('global.errors.unknownSign')+": "+missatgeError;											
										});
						            }
					                AutoScript.sign(dataB64, algorithm, format, params, signaturaAutofirmaRealitzada, signaturaAutofirmaError);
								}catch(e){
									$rootScope.loadingDiv = false;
									SignService.updateScreen().then(function(data) {
										$scope.documentsToSign[index].signState = 'error';
		                        		$scope.documentsToSign[index].error = $filter('translate')('global.errors.unknownSign')+": "+e;
									}).catch(function(error) {
										$scope.documentsToSign[index].signState = 'error';
		                        		$scope.documentsToSign[index].error = $filter('translate')('global.errors.unknownSign')+": "+e;								
									});
								}
							}else{
								$rootScope.loadingDiv = false;
								SignService.updateScreen().then(function(data) {
									$scope.documentsToSign[index].signState = 'error';
	                        		$scope.documentsToSign[index].error = $filter('translate')('global.errors.unknownSign');
								}).catch(function(error) {
									$scope.documentsToSign[index].signState = 'error';
	                        		$scope.documentsToSign[index].error = $filter('translate')('global.errors.unknownSign');						
								});
							} 
                       });
                   }).catch(function(error) {
                       _.forEach(documentsToSign, function(item) {
                           item.signState = 'error';
                           item.error = $filter('translate')('global.errors.unknownSign');
                           item.error += ': ' + error;
                       })
                   })
               }

               $scope.$on('appletSignatureError', function (event, args) {
                   checkCompleted();

                   var index = $linq($scope.documentsToSign).indexOf("x => x.id == " + args.documentToSign.actionId);

                   if(index != -1) {
                       $scope.documentsToSign[index].signState = 'error';
                       $scope.documentsToSign[index].error = $filter('translate')('global.errors.unknownSign');
                       $scope.documentsToSign[index].error += ': ' + args.msg;
                   }
               });

               $scope.$on('appletSignatureOK', function (event, args) {
                   var index = $linq($scope.documentsToSign).indexOf("x => x.id == " + args.documentToSign.actionId);

                   if(index != -1) {
                       $http({
                           method: 'POST',
                           url: './api/sign/dolocal',
                           data: {
                               signed: args.signedFile,
                               documentId: args.documentToSign.document.id,
                               order: args.documentToSign.actionOrder,
                               signProcessActionId: args.documentToSign.actionId,
                               position: args.documentToSign.position,
                               firstpage: args.documentToSign.signFirstPage,
                               addComments: args.documentToSign.addComments
                           }
                       }).then(function(data) {
                           checkCompleted();

                           if(data) {
                               $scope.documentsToSign[index].signState = 'complete';
                           } else {
                               $scope.documentsToSign[index].signState = 'error';
                               $scope.documentsToSign[index].error = $filter('translate')(data.error);
                           }
                       }).catch(function(error) {
                           checkCompleted();

                           $scope.documentsToSign[index].signState = 'error';
                           $scope.documentsToSign[index].error = $filter('translate')('global.errors.unknownSign');
                           $scope.documentsToSign[index].error += ': ' + error;
                       })
                   }
               })
            }],
            link: function(scope, element, attr) {
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);
            }
        }
    }]);